<template>
	<div class="data-content">
		<div class="top-box">
			<button class="add-btn" @click="handleEditData('add')" v-if="perms.includes('train:room:add')">新增</button>
		</div>
		<div class="data-list-box">
			 <el-table
				:data="roomList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				style="width: 100%">
				<el-table-column
					label="NO."
					width="60">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="name"
					show-overflow-tooltip
					label="会场名称">
				</el-table-column>
				<el-table-column
					prop="capNum"
					show-overflow-tooltip
					label="容纳人数">
				</el-table-column>
				<el-table-column
					prop="rangeName"
					show-overflow-tooltip
					label="地区">
				</el-table-column>
				<el-table-column
					prop="className"
					show-overflow-tooltip
					label="教室名称">
				</el-table-column>
				<el-table-column
					label="操作"
					width="130">
					<template slot-scope="scope">
						<button class="btn-blue" @click="handleEditData('check',scope.row)">查看</button>
						<button class="btn-blue" @click="handleEditData('edit',scope.row)" v-if="perms.includes('train:room:update')">编辑</button>
						<button class="btn-red" @click="handleDeleteData(scope.row)" v-if="perms.includes('train:room:delete')">删除</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
		<edit-data ref="EditData" @getRoomList="getRoomList"></edit-data>
	</div>
</template>
<script>
import EditData from './components/edit-data'
import Pagination from '@/components/Pagination'
export default {
	components: {
		EditData,
		Pagination
	},
	data() {
		return {
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			pageNo: 1,
			pageSize: 20,
			total: 0,
			roomList: [],
			visible: false
		}
	},
	created() {
		this.getRoomList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 新增/编辑/查看角色
		handleEditData(type,data) {
			this.$refs.EditData.init(type,data);
		},
		// 获取列表数据
		getRoomList() {
			let that = this;
			that.$request.get(
				"roomList",
				true,
				{
					pageNo: that.pageNo,
					pageSize: that.pageSize
				},
				function (r) {
					if (r.code == "0") {
						that.roomList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 删除
		handleDeleteData(data) {
			this.$confirm('是否删除当前会场?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'deleteRoom',
					true,
					{
						id: data.id
					},
					function (r) {
						if (r.code == "0") {
							that.getRoomList();
							that.$message.success('删除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		handleSizeChange() {
			this.getRoomList();
		},
		handleCurrentChange() {
			this.getRoomList();
		},
		handleSearch() {
			this.pageNo = 1;
			this.getRoomList();
		}
	}
}
</script>
<style lang="scss" scoped>
.block{
	display: block;
}
.data-content{
	.top-box{
		height: 32px;
		margin-bottom: 20px;
	}
	.add-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		float: right;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
	}
	.btn-blue{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.error-list{
	height: 50vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
	li{
		list-style: none;
	}
}
</style>
<style>
	.el-message .user-message{
		margin: 15px;
	}
</style>
