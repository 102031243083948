<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="500px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-width="80px" ref="dataForm">
            <el-form-item label="会场名称" prop="name">
                <el-input size="small" v-model="dataForm.name" placeholder="会场名称" maxlength="20" :disabled="readonly"></el-input>
            </el-form-item>
			<el-form-item label="容纳人数" prop="capNum">
                <el-input size="small" v-model="dataForm.capNum" placeholder="容纳人数" maxlength="4" :disabled="readonly"></el-input>
            </el-form-item>
			<el-form-item label="区域" prop="rangeId">
				<el-select size="small" v-model="dataForm.rangeId" filterable :disabled="readonly">
					<el-option v-for="(item,i) in areaList" :key="i" :value="item.id" :label="item.name"></el-option>
				</el-select>
            </el-form-item>
			<el-form-item label="教室名称" prop="classId">
				<el-select size="small" v-model="dataForm.classId" filterable :disabled="readonly">
					<el-option v-for="(item,i) in roomList" :key="i" :value="item.id" :label="item.name"></el-option>
				</el-select>
            </el-form-item>
        </el-form>
		<span slot="footer" class="dialog-footer">
			<el-button class="submit-btn" @click="submit" v-if="title != '查看会场'">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
import vPinyin from '@/utils/characterToPinYing'
export default {
	data() {
		return {
			title: '新增会场',
			visible: false,
			readonly: false,
			areaList: [],
			roomList: [],
			submitUrl: 'addRoom',
			dataForm: {
				name: '',
				capNum: '',
				rangeId: '',
				classId: ''
			},
			dataRule: {
				name: [
					{required: true,message: '会场名称不能为空', trigger: "blur"},
					// {pattern: /^[A-Z0-9]{4,10}$/, message: '请输入最少4位大写英文或数字'}
				],
				capNum: [
					{required: true,message: '容纳人数不能为空', trigger: "blur"},
					{pattern: /^[1-9]\d*$/, message: '请输入大于0的正整数'}
				],
				rangeId: [
					{required: true,message: '请选择区域', trigger: "change"}
				],
				classId: [
					{required: true,message: '请选择教室', trigger: "change"}
				]
			}
		}
	},
	methods: {
		init(type,data) {
			this.visible = true;
			this.getDataList();
			this.$nextTick(() => {
				this.$refs.dataForm.resetFields();
				if(type == 'add') {
					this.title = '新增会场';
					this.submitUrl = 'addRoom';
					this.readonly = false;
					delete this.dataForm.id;
				}
				else if(type == 'edit') {
					this.title = '修改会场';
					this.submitUrl = 'updateRoom';
					this.readonly = false;
					this.dataForm.id = data.id;
					this.dataForm.name = data.name;
					this.dataForm.capNum = data.capNum;
					this.dataForm.rangeId = data.rangeId;
					this.dataForm.classId = data.classId;
				}
				else {
					this.title = '查看会场';
					this.submitUrl = '';
					this.readonly = true;
					this.dataForm.id = data.id;
					this.dataForm.name = data.name;
					this.dataForm.capNum = data.capNum;
					this.dataForm.rangeId = data.rangeId;
					this.dataForm.classId = data.classId;
				}
			})

		},
		// 获取会场数据
		getDataList() {
			let that = this;
			that.$request.post(
				"sysDataDetailPageList",
				false,
				{
					pageNo: 1,
					pageSize: 999,
					code: 'areaType'
				},
				function (r) {
					if (r.code == "0") {
						that.areaList = r.data.list;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
			that.$request.post(
				"sysDataDetailPageList",
				false,
				{
					pageNo: 1,
					pageSize: 999,
					code: 'classRoom'
				},
				function (r) {
					if (r.code == "0") {
						that.roomList = r.data.list;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 提交
		submit:debounce(function() {
			if(this.visible == false) {
				return
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					let that = this;
					that.$request.post(
						that.submitUrl,
						true,
						that.dataForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$emit('getRoomList');
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
						}
					);
				} else {
					return false;
				}
			});
		},1000)
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.el-form-item{
	margin-bottom: 18px;
	.el-select{
		width: 100%;
	}
}
.submit-btn{
	width: 100%;
	background-color: #117FFC;
	color: #fff;
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	// padding: 0 !important;
	// line-height: 24px;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 20px 20px 40px !important;
}
</style>
